<template>
  <div id="user-profile">
    <b-navbar class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
      <navbar />
    </b-navbar>
    <b-container class="mb-3">
      <br />
      <br />
      <h3 class="text-primary text-center mb-2">Pembahasan Soal</h3>

      <b-overlay :show="loading">
        <b-card>
          <h4 class="text-center">{{ hasil.ujian_name }}</h4>
          <b-row class="">
            <b-col md="12" class="my-1">
              <div class="table-responsive">
                <table class="table table-bordered responsive">
                  <tr class="bg-primary text-white" variant="primary">
                    <th>No</th>
                    <th>Jawaban Kamu</th>
                    <th>Kunci Jawaban</th>
                    <!-- <th>T</th> -->
                    <th>Pembahasan</th>
                  </tr>

                  <tr v-for="(item, i) in pembahasan" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td v-html="jawabanKamu(item)"></td>
                    <td v-html="kunciJawaban(item)"></td>
                    <!-- <td><b-badge variant="success">0</b-badge></td> -->
                    <td class="text-center">
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.pembahasan variant="outline-primary" @click.prevent="lihat(item, i)"> Lihat </b-button>
                    </td>
                  </tr>
                </table>
              </div>
            </b-col>
          </b-row>
          <b-modal modal-class="modal-primary" id="pembahasan" title="Pembahasan" header-bg-variant="primary" header-text-variant="light" hide-footer>
            <b-card-text>
              <h5>No. {{ noPembahasan }}</h5>
              <h6 v-if="detailPembahasan.type != 'pauli'">
                <p v-html="detailPembahasan.content"></p>
              </h6>
              <h6 v-else>
                <br />
                <b>
                  <p v-html="detailPembahasan.name"></p>
                </b>
              </h6>
              <div class="" v-if="detailPembahasan.type != 'pauli'">
                <div class="mt-2" v-for="(item, i) in detailPembahasan.options" :key="i">
                  <div class="" v-if="detailPembahasan.type == 'pg'">
                    <span v-if="detailPembahasan.answer != item.id">
                      {{ item.option }}. {{ item.is_correct == 1 ? "Benar" : "Salah" }}
                      <p v-html="item.content"></p>
                    </span>
                    <span v-else>
                      {{ item.option }}. {{ item.is_correct == 1 ? "Benar" : "Salah" }} <b>(Jawaban Kamu)</b>
                      <p v-html="item.content"></p>
                    </span>
                  </div>
                  <div class="" v-else-if="detailPembahasan.type == 'multiple_choice'">
                    <span v-if="detailPembahasan.answer != item.id">
                      {{ item.option }}. {{ item.is_correct == 1 ? "Benar" : "Salah" }}
                      <p v-html="item.content"></p>
                    </span>
                    <span v-else>
                      {{ item.option }}. {{ item.is_correct == 1 ? "Benar" : "Salah" }} <b>(Jawaban Kamu)</b>
                      <p v-html="item.content"></p>
                    </span>
                  </div>
                  <div class="" v-else-if="detailPembahasan.type == 'benar_salah'">
                    <span v-if="detailPembahasan.answer != item.id">
                      {{ item.option }}. {{ item.is_true_or_false }}
                      <p v-html="item.content"></p>
                    </span>
                    <span v-else>
                      {{ item.option }}. {{ item.is_true_or_false }} <b>(Jawaban Kamu)</b>
                      <p v-html="item.content"></p>
                    </span>
                  </div>
                </div>
              </div>
              <div class="" v-else>
                <div class="mt-2" v-for="(item, i) in detailPembahasan.sub_soal" :key="i">
                  <b> {{ i + 1 }}. </b> <span v-html="item.content"></span> : <b> {{ item.secret_key }} </b> <br />
                  Jawaban Kamu : <b> {{ item.is_correct_answer == 1 ? "Benar" : "Salah" }} </b>
                </div>
              </div>
              <div class="mt-4" v-if="detailPembahasan.pembahasan != null">
                <h6>Pembahasan :</h6>
                <p v-html="detailPembahasan.pembahasan"></p>
              </div>
            </b-card-text>
          </b-modal>
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>

<script>
import {
  BOverlay,
  BModal,
  BMedia,
  BRow,
  BCol,
  BContainer,
  BCard,
  BCardBody,
  BCardFooter,
  BBadge,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";

import Navbar from "./components/Navbar.vue";
import vSelect from "vue-select";
/* eslint-disable global-require */
export default {
  components: {
    vSelect,
    BOverlay,
    BModal,
    BMedia,
    BRow,
    BCol,
    BContainer,
    BCard,
    BCardBody,
    BCardFooter,
    BBadge,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    Navbar,
  },

  data() {
    return {
      pembahasan: [],
      noPembahasan: 1,
      detailPembahasan: {},
      loading: false,
    };
  },

  computed: {
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
    hasil() {
      return this.$store.state.ujian.hasil;
    },
  },

  methods: {
    lihat(item, i) {
      this.noPembahasan = i + 1;
      this.detailPembahasan = item;
    },
    getPembahasan() {
      this.loading = true;
      let params = {
        hasil_id: this.hasil.hasil_id,
      };
      this.$store
        .dispatch("ujian/indexPembahasan", params)
        .then((res) => {
          this.loading = false;
          if (res.data.data) {
            this.pembahasan = res.data.data;
          } else {
            this.pembahasan = res.data;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },

    jawabanKamu(item) {
      if (item.type == "pg") {
        let jawaban = item.options.find((res) => {
          return res.id == item.answer;
        });
        if (jawaban != undefined) {
          var str = jawaban.content;
          str = str.replace("<p>", "<span>");
          str = str.replace("</p>", " </span>");
          var html = `${jawaban.option}. ${str}`;
        } else {
          var html = "Jawaban tidak ada";
        }
        return html;
      } else if (item.type == "multiple_choice") {
        var html = "";
        if (item.answer != null) {
          item.options.forEach((val) => {
            if (item.answer.includes(val.id.toString())) {
              var str = val.content;
              str = str.replace("<p>", "<span>");
              str = str.replace("</p>", " </span>");
              html += `${val.option}. ${str}` + "<br/>";
            }
          });
        } else {
          html = "Jawaban tidak ada";
        }
        return html;
      } else if (item.type == "benar_salah") {
        var html = "";
        if (item.answer != null) {
          let arrayJawaban = item.answer.split(",");
          arrayJawaban.forEach((v) => {
            let hasil = v.split(`:`);
            let jawaban = item.options.find((res) => {
              return res.id == hasil[0];
            });
            if (jawaban != undefined) {
              html += `${jawaban.option}. ${hasil[1]}` + "<br/>";
            } else {
              html = "Jawaban tidak ada";
            }
          });
        } else {
          html = "Jawaban tidak ada";
        }
        return html;
      } else {
        var html = "";
        html += `<h6>${item.name}</h6>`;
        item.sub_soal.forEach((res, i) => {
          html += ` <b>${i + 1}.</b> <span>${res.answer != null ? res.answer : "kosong"}</span> <br> `;
        });
        return html;
      }
    },
    kunciJawaban(item) {
      if (item.type == "pg") {
        let kunciJawaban = item.options.find((res) => {
          return res.id == item.secret_key;
        });
        if (kunciJawaban != undefined) {
          var str = kunciJawaban.content;
          str = str.replace("<p>", "<span>");
          str = str.replace("</p>", " </span>");
          var html = `${kunciJawaban.option}. ${str}`;
        } else {
          var html = "Kunci Jawaban tidak ada";
        }
        return html;
      } else if (item.type == "multiple_choice") {
        var html = "";
        if (item.secret_key != null) {
          item.options.forEach((val) => {
            if (item.secret_key.includes(val.id.toString())) {
              var str = val.content;
              str = str.replace("<p>", "<span>");
              str = str.replace("</p>", " </span>");
              html += `${val.option}. ${str}` + "<br/>";
            }
          });
        } else {
          html = "Kunci Jawaban tidak ada";
        }
        return html;
      } else if (item.type == "benar_salah") {
        var html = "";
        if (item.secret_key != null) {
          let arrayJawaban = item.secret_key.split(",");
          arrayJawaban.forEach((v) => {
            let hasil = v.split(`:`);
            let jawaban = item.options.find((res) => {
              return res.id == hasil[0];
            });
            if (jawaban != undefined) {
              html += `${jawaban.option}. ${hasil[1]}` + "<br/>";
            } else {
              html = "Jawaban tidak ada";
            }
          });
        } else {
          html = "Jawaban tidak ada";
        }
        return html;
      } else {
        var html = "";
        html += `<h6>${item.name}</h6>`;
        item.sub_soal.forEach((res, i) => {
          html += ` <b>${i + 1}.</b> <span>${res.secret_key}</span> <br> `;
        });
        return html;
      }
    },
  },

  created() {
    this.getPembahasan();
  },
};
</script>
